<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5 v-t="`Filters`" />
    </b-card-header>
    <b-card-body>
      <b-row>
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="6"-->
        <!--          class="mb-md-0 mb-2"-->
        <!--        >-->
        <!--          <label v-t="`inputs.category`" />-->
        <!--          <v-select-->
        <!--            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--            :value="categoryFilter"-->
        <!--            :options="categoryOptions"-->
        <!--            class="w-100"-->
        <!--            :reduce="val => val.value"-->
        <!--            @input="(val) => $emit('update:categoryFilter', val)"-->
        <!--          />-->
        <!--        </b-col>-->
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
          <label v-t="`inputs.status`" />
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from 'vue'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import productCategoryStoreModule from '@/views/models/product_categories/productCategoryStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    // categoryFilter: {
    //   type: [Number, null],
    //   default: null,
    // },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'product_categories'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, productCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // const categoryOptions = ref([])
    //
    // store.dispatch('products/fetchCategories')
    //   .then(response => { categoryOptions.value = response.data.data })

    const { t } = useI18nUtils()
    const statusOptions = [
      { label: t('Active'), value: 1 },
      { label: t('Inactive'), value: 0 },
    ]

    return {
      // categoryOptions,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
